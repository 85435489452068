import uvapeImage from '../../img/uvape.jpg';
import carshaulerImage from '../../img/carshauler.jpg';
import kaunoGrudaiImage from '../../img/kaunoGrudai.jpg';
import vistalt24Image from '../../img/drivelab.jpg';
import drivelabImage from '../../img/drivelab.jpg';

const projectsData = [
    {
        id: 1,
        img: uvapeImage,
        clientImg: uvapeImage,
        type: "Telegram Bot",
        title: 'Uvape',
        tags: ['ReactJS', 'Python', 'MySQL'],
        client: "One of the pioners of vaping in Ukraine has established their first store more than 8 years ago. Now they are among the top three market leaders with thousands of reviews, numerous mentions in theme journals and magazines and hundreds of offline store all over Ukraine",
        smallDescription: 'Telegram MiniApp store to simplify customer interaction with features like seamless authorization, built-in notifications, fast performance, and an all-in-one interface.',
        fullDescription: 'Leading electronic cigarettes shop in the country wanted to simplify customers interaction shifting from standart e-stroe to a modern Telegram MiniApp store',
        solution: [
            {
                key: "Seamless authorization",
                text: "User don't need to go through a long survey of questions in order to create an account like on traditional websites. In bot you can register an account in just 2 clicks!"
            },
            {
                key: "Built-in notification system",
                text: "Повідомлення в мессенджерах мають відсоток відкриваємості 82%, причому тексти зазвичай читаються протягом 5 секунд"
            },
            {
                key: "Lightning speed",
                text: "Наш середній PageSpeed Index становить 90+, що дає змогу користувачу робити покупки з комфортом"
            },
            {
                key: "All-in-one place interface",
                text: "You can track your order history, see your cashback balance and bonus card, find the nearest store and more!"
            }
        ]
    },
    {
        id: 2,
        img: carshaulerImage,
        title: 'CarsHauler',
        tags: ['ReactJS', 'Python', 'MySQL'],
        smallDescription: 'Software to manage truck maintenance, remind drivers about truck services, and streamline processes such as truck pick-up, change, and return.',
        fullDescription: 'Software to manage truck maintenance, remind drivers about truck services, and streamline processes such as truck pick-up, change, and return.',
        solution: {
            key_features: [
                'Corporate Messenger with department bots',
                'Notification system for truck services',
                'Personal cabinet for task management',
                'CRM integration'
            ]
        }
    },
    {
        id: 3,
        img: kaunoGrudaiImage,
        title: 'Kauno Grūdai',
        tags: ['ReactJS', 'Python', 'OpenAI API', 'Custom Embedding Models', 'Pinecone'],
        smallDescription: 'AI-powered bot to help employees navigate extensive documentation, featuring improved accuracy over time through human feedback.',
        fullDescription: 'AI-powered bot to help employees navigate a large documentation base, with a system that improves based on human feedback.',
        solution: {
            key_features: [
                'Documentation vectorization',
                'Relevant chunks search',
                'Fine-tuning embedding model for improved accuracy'
            ]
        }
    },
    {
        id: 4,
        img: vistalt24Image,
        title: 'Vistalt24',
        tags: ['ReactJS', 'Python', 'OpenAI API', 'Custom Embedding Models', 'Pinecone'],
        smallDescription: 'AI-powered bot to assist employees in navigating CRM documentation efficiently.',
        fullDescription: 'AI-powered bot to help employees navigate CRM documentation.',
        solution: {
            key_features: [
                'Documentation vectorization',
                'Fine-tuning embedding model',
                'Relevant chunks search'
            ]
        }
    },
    {
        id: 5,
        img: drivelabImage,
        title: 'Drivelab',
        tags: ['ReactJS', 'Python', 'OpenAI API', 'Custom Embedding Models', 'Pinecone'],
        smallDescription: 'AI-powered bot designed to help employees get around extensive CRM documentation with advanced search capabilities.',
        fullDescription: 'AI-powered bot to help employees navigate CRM documentation.',
        solution: {
            key_features: [
                'Documentation vectorization',
                'Fine-tuning embedding model',
                'Relevant chunks search'
            ]
        }
    }
];

export default projectsData;
