import React from 'react'
import './Link.css';
import {Link as Wrapper} from 'react-scroll'

function Link(props) {
  return (
    <div className='link'>
    <Wrapper to={props.link} spy={true} smooth={true} offset={-50} duration={500} delay={0}>

        <a onClick={props.onClick}>
          {props.text}
        </a>
    </Wrapper>

    </div>
  );
}

export default Link;
