function Partner({ title, logo, country, link }) {
    return (
        <div className="PartnerContainer" >
            <a href={link} target="_blank">

                <div className="PartnerLogoContainer">
                    <img
                        src={logo}
                        alt={title}
                        className="PartnerLogo"
                    />
                </div>

                <div className="PartnerTitle">
                    {title}
                </div>
                <img
                    src={country}
                    alt={title}
                    className="CountryLogo"
                />
            </a>
        </div>
    );
}

export default Partner;