import React, { useRef, useState } from 'react';
import './Partners.css';
import partnersdata from "./partnersdata";
import Partner from './Partner';

function Partners() {
  const partnersGridRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - partnersGridRef.current.offsetLeft);
    setScrollLeft(partnersGridRef.current.scrollLeft);
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - partnersGridRef.current.offsetLeft;
    const walk = (x - startX);
    partnersGridRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      className='PartnersGrid'
      ref={partnersGridRef}
      onMouseDown={startDragging}
      onMouseLeave={stopDragging}
      onMouseUp={stopDragging}
      onMouseMove={onDrag}
    >
      {partnersdata.map((item) => (
        <Partner
          key={item.id}
          title={item.title}
          logo={item.logo}
          country={item.country}
          link={item.link}
        />
      ))}
    </div>
  );
}

export default Partners;
