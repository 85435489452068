import React, { useState } from 'react';
import Link from '../Link/Link'
import './Header.css';
import { Link as Wrapper } from 'react-scroll'

const AdditionalHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <nav className="nav additional">
        <Wrapper to='start' spy={true} smooth={true} offset={0} duration={500} delay={0}><div className="logo"><img src='/logo.svg'></img></div></Wrapper>
        <button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? (
            //
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#313132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 6L18 18" stroke="#313132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            //
          ) : (
            // <Wrapper to='/' spy={true} smooth={true} offset={0} duration={500}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 6H20M4 12H20M4 18H20" stroke="#313132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            // </Wrapper>
          )}
        </button>
        <div className={`links ${isMenuOpen ? 'open' : ''}`}>
          <Link text="CONTACT" link="contact" onClick={closeMenu} />
        </div>
      </nav>
    </header>
  );
};

export default AdditionalHeader;