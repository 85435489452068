import { Particles } from 'react-tsparticles'
import { loadFull } from 'tsparticles'
import {useCallback} from "react";


const ParticlesContainer = ({ count }) => {

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, [])

  const particlesLoaded = useCallback(async () => {}, []);
  return (
    <Particles
      id='tsparticles'
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        fullScreen: { enable: true },
        background: {
          color: {
            value: '',
          }
        },
        fps_limit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: false,
              mode: 'push',
            },
            onHover: {
              enable: true,
              mode: 'repulse',
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 30
            },
            repulse: {
              distance: 100,
              duration: 0.7,
            }
          }
        },
        particles: {
          color: {
            value: '#0372dc'
          },
          links: {
            color: '#7ebffb',
            distance: 300,
            enable: true,
            opacity: 0.5,
            width: 1
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: 'none',
            enable: true,
            outModes: {
              default: 'bounce',
            },
            random: false,
            speed: 1.8,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 800
            },
            value: count
          },
          opacity: {
            value: 0.5
          },
          shape: {
            type: 'circle',
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  )
}
export default ParticlesContainer