import React, { useEffect } from 'react'
import './About.css'
import ScrollReveal from "scrollreveal";
import mainbanner from "./mainbanner.png"
export default function About() {
  useEffect(() => {

    ScrollReveal().reveal('.img_container',
      {
        delay: 500,
        distance: '100px',
        origin: 'left',
        opposite: false,
        interval: 500,

      });

    ScrollReveal().reveal('.text_about_container',
      {
        delay: 1000,
        distance: '100px',
        origin: 'bottom',
        opposite: false,
        interval: 500,

      });

  }, []);
  return (
    <section className='About' >
      <div className='img_container'>
        <img src='https://media.leetsoft.org/media/about_us.jpg' className='img'></img>
      </div>
      <div className='text_about_container'>
        <div className='about_text'>
          Welcome to LeetSoft, your partner in the digital world. Born from the shared vision of two friends, our company specializes in the development of immersive websites, AI-based bots and bussines automation tools.

          <div className='hidden_text'>Explore our portfolio to see how we've transformed ideas into working tools. Join us at LeetSoft and let's implement your idea together</div>
        </div>
        <a href='#contact'><button className='btn_contact_us'> CONTACT US</button></a>
      </div>
    </section>
  )
}
