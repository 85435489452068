import React from 'react'
import Long_containers from './Long_containers'
import Square_container from './Square_container'
import './Advantages.css';
function Advantages() {
  return (
    <section className='Advantages' >
      {/* <Long_containers title="Client Satisfaction" content="   We prioritize your satisfaction and have a track record of successful projects, earning recommendations from satisfied clients." />
      <Long_containers title="Reliable Performance" content="We guarantee exceptional software uptime, competitive pricing, and clean code, providing excellent value for money (VfM)." />
      <Long_containers title="On-Time Delivery" content=" We meet deadlines with a 99.8% success rate, thanks to robust security measures and realistic project schedules." /> */}

      <div className='square'>
        <Square_container title="Speed" icon="icon1.png" content="We're dedicated to delivering results quickly, according to our deadlines. Timely project completion is our priority." />
        <Square_container title="Quality" icon="icon2.png" content=" We ensure that our projects are technically well-designed, making them easy to maintain and allowing for the addition of new features." />
        <Square_container title="Best practices " icon="icon4.png" content="We always keep an eye on the industry's best practices to implement our clients' features as effectively as possible." />
      </div>

    </section>

  )
}

export default Advantages
