const partnersdata = [
    {
      id: 1,
    //   title: 'E-Shop',
      logo: "https://media.leetsoft.org/media/uvape-logo.png",
      country:"https://media.leetsoft.org/media/ukraine-flag.webp",
      link:"https://uvape.pro"
      
    },
    {
      id: 2,
    //   title: 'Messenger',
      logo: "https://media.leetsoft.org/media/carshauler-logo.jpg",
      country:"https://media.leetsoft.org/media/usa-flag.webp",
      link:"https://carshauler.com/"
    },
    {
      id: 3,
    //   title: 'CRM-system',
      logo: "https://media.leetsoft.org/media/kauno-grudai.png",
      country:"https://media.leetsoft.org/media/lithuania-flag.png",
      link:"https://kaunogrudai.lt"
    },
    {
      id: 4,
    //   title: 'Vistalt',
      logo: "https://media.leetsoft.org/media/vistalt-logo.webp",
      country:"https://media.leetsoft.org/media/germany-flag.png",
      link:"https://vistalt.de/"
      
    },
    {
      id: 5,
    //   title: 'driverlab',
      logo: "https://media.leetsoft.org/media/drivelab-logo.png",
      country:"https://media.leetsoft.org/media/czechia-flag.svg",
      link:"https://www.drivelab.cz/"
      
    }
    
  ];
  
  export default partnersdata;
