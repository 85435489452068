import React from 'react'
import './Service.css'
function service(props) {
  return (
    <div className='service'>
      <div className='service_title'>
        <div className='counter'>{props.counter}</div>
        <div className='service_title_text'>{props.title}</div>
      </div>
      <div className='content_service'>{props.content}</div>
    </div>
  )
}

export default service