import React from 'react'
import './Title.css';
function title(props) {
  return (
    <section className='Title'>
      <div className='text_container'>
        <div className='zaglav' id={props.id}>
          {props.text}
        </div>
      </div>
      <hr className='line'></hr>
    </section>
  )
}

export default title