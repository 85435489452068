import React from 'react';
import { useParams } from 'react-router-dom';
import projectsData from '../Components/ProjectsContainer/portfolio';
import AdditionalHeader from '../Components/Header/AdditionalHeader';
import Title from "../Components/Title/Title";
import './ProjectDetails.css'

function ProjectDetails() {
  const { id } = useParams();
  const project = projectsData.find((proj) => proj.id === parseInt(id));

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <>  
    <AdditionalHeader />
        <div className="ProjectDetails">
            <section className='product'>
                <div className="projectIfon">
                    <h1>{project.type}</h1>
                    <h2>{project.title}</h2>
                    <p>{project.fullDescription}</p>
                    <div className="tags">
                        {project.tags.map((tag, index) => (
                            <span key={index}>#{tag}</span>
                        ))}
                    </div>
                </div>
                <div className="imageContainer">
                    <img src={project.img} />
                    <img src={project.img} />
                </div>
                <div className="serpantOne">
                <svg width="4308" height="750" viewBox="0 0 4308 750" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="642" width="4129" height="107" fill="#7177FF"/>
                <rect x="4183" width="114" height="107" fill="#7177FF"/>
                <rect x="4127" y="644" width="589" height="107" transform="rotate(-90 4127 644)" fill="#7177FF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4158.77 568L4053.42 673.35L4129.08 749.01L4234 644.094V643.226L4158.77 568Z" fill="#7177FF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4182.35 0L4127 55.3497L4202.66 131.01L4307.58 26.0944V25.2265L4282.35 0H4182.35Z" fill="#7177FF"/>
                </svg>

                </div>
            </section>
            <section className="client">
                <div className="clentInfo">
                    <Title text="Client" />
                    <div className="sectionContainer">
                        <p>{project.client}</p>
                    </div>
                </div>
                <img src={project.clientImg} />
            </section>
            <section className='solution'>
                <div className="serpantTwo">
                    <svg width="181" height="700" viewBox="0 0 181 700" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="56" width="114" height="107" fill="#86F2FF"/>
                        <rect y="644" width="589" height="107" transform="rotate(-90 0 644)" fill="#86F2FF"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.3496 0L0 55.3497L75.6602 131.01L180.576 26.0944V25.2265L155.35 0H55.3496Z" fill="#86F2FF"/>
                        <rect width="114" height="107" transform="matrix(1 0 0 -1 56 699.01)" fill="#86F2FF"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.3497 699.01L0 643.66L75.6604 568L180.576 672.916V673.784L155.35 699.01H55.3497Z" fill="#86F2FF"/>
                    </svg>
                </div>
                <Title text="Solution" />
                <div className="sectionContainer">
                    <div className="solutionGrid">
                        {project.solution.map((sol, index) => (
                            <div key={index} className="solutionItem">
                                <h3>{`${index + 1}. ${sol.key}`}</h3>
                                <p>{sol.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='tehnologies'>
                <Title text="Tehnologies" />
                <div className="tehnologiesContainer">
                    <div className="item">
                        <h2>Backend</h2>
                        <ul>
                            <li>Node.js</li>
                        </ul>
                    </div>
                    <div className="item">
                        <h2>Databese</h2>
                        <ul>
                            <li>PostgreSQL</li>
                        </ul>
                    </div>
                    <div className="item">
                        <h2>Frontend</h2>
                        <ul>
                            <li>Next.js</li>
                            <li>Next.js</li>
                        </ul>
                    </div>
                </div>
                <div className="serpantFour">
                <svg width="4308" height="750" viewBox="0 0 4308 750" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="642" width="4129" height="107" fill="#7177FF"/>
<rect x="4183" width="114" height="107" fill="#7177FF"/>
<rect x="4127" y="644" width="589" height="107" transform="rotate(-90 4127 644)" fill="#7177FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4158.77 568L4053.42 673.35L4129.08 749.01L4234 644.094V643.226L4158.77 568Z" fill="#7177FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4182.35 0L4127 55.3497L4202.66 131.01L4307.58 26.0944V25.2265L4282.35 0H4182.35Z" fill="#7177FF"/>
</svg>

                </div>
            </section>
        </div>
    </>
  );
}

export default ProjectDetails;
