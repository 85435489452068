import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import './Services.css';
import Service from './Service';
function Services() {


  // const observer = new IntersectionObserver((entries) => {
  //   entries.forEach((entry) => {
  //     if (entry.isIntersecting) {
  //       entry.target.classList.add('show');
  //     } else {
  //       entry.target.classList.remove('show');
  //     }
  //   })
  // })
  //
  // const hiddenElements= document.querySelectorAll('.service')
  // hiddenElements.forEach((el) => observer.observe(el))

  useEffect(() => {

    ScrollReveal().reveal('.service',
      {
        delay: 500,
        distance: '100px',
        origin: 'left',
        opposite: false,
        interval: 500,
        // reset: true
      });

    ScrollReveal().reveal('.solid_line',
      {
        delay: 700,
        distance: '100px',
        origin: 'left',
        opposite: false,
        interval: 500,
        // reset: true
      });

  }, []);

  return (
    <section className='Services'>
      <Service counter="01" title="WEB DEVELOPMENT" content="We offer our customers a wide range of web services starting from selling landing pages to giant multifunctional web services. We use latest industry experience and techonologies, so you can be sure that you will receive all possible advantages out of them." />
      <div className='solid_line'></div>
      <div className='solid_line'></div>
      <Service counter="02" title="MOBILE DEVELOPMENT" content="For those who want to reach a larger amount of people and increase customer level of satisfaction using their phones - mobile application is your choice! We can build a multiplatform mobile application for your business, no matter if it is food service franchise or private clinic." />
      <Service counter="03" title="IT CONSULTING" content="You know that you can optimise some of your business proccesses but dont know particulary what? Dont hesitate to reach out to us to discuss your problem and come up with a solution" />
      <div className='solid_line'></div>
      <div className='white_space'></div>
      <Service counter="04" title="SOFTWARE DEVELOPMENT" content="If you want to develop your custom software solution or part of it, we have a deep expertise in many industry fields. Let's discuss your tech stack and start moving together!" />
    </section>
  );
}

export default Services;
