import React, { useEffect } from 'react'
import "./Process.css";
import Process_content from "./Process_content";
import settings from "./settings.png"
import browser from "./browser.png"
import laptop from "./laptop.png"
import ScrollReveal from "scrollreveal";



function Process() {

  useEffect(() => {

    // ScrollReveal().reveal('#block1',
    //   {
    //     delay: 1000,
    //     distance: '100px',
    //     origin: 'left',
    //     opposite: false,
    //     interval: 500,
    //   });
    ScrollReveal().reveal('#block2',
      {
        delay: 1000,
        distance: '100px',
        origin: 'left',
        opposite: false,
        interval: 500,
      });
    ScrollReveal().reveal('#block3',
      {
        delay: 2000,
        distance: '100px',
        origin: 'left',
        opposite: false,
        interval: 500,
      });


  }, []);

  return (
    <div className="how-we-work-container">

      <div className="Blocks">

        <div className="stage ">

          <div className="icon"><img src={settings} alt="Idea Icon" /></div>
          <div id="block1">
            <h3>SHARE YOUR IDEA</h3>
            <p > We want to hear about your product idea. Leave your application on our website, and we'll reach out to understand your vision and its core business value. Your idea is our starting point.</p>
          </div>
        </div>

        <div className="stage" id="block2">
          <div className="icon"><img src={browser} alt="Development Icon" /></div>
          <div id="block">
            <h3>DEVELOPMENT AND FLEXIBILITY</h3>
            <p>We implement your plan while staying flexible. We keep in touch, adapt to changes, and communicate throughout the development process, ensuring your project aligns with your goals.</p>
          </div>
        </div>

        <div className="stage" id="block3">
          <div className="icon"><img src={laptop} alt="Launch Icon" /></div>
          <div >
            <h3>LAUNCH WITH ENTHUSIASM</h3>
            <p >It's the exciting "yahoo!" moment. Our experts work passionately to launch your project successfully. Afterward, we continue to support and develop it further.</p>
          </div>
        </div>
      </div>

    </div>
  );
}



export default Process;
