import Header from "./Components/Header/Header";
import Introduce from "./Components/Introduce/Introduce";
import Title from "./Components/Title/Title";
import About from "./Components/AboutUs/About";
import Services from "./Components/Service/Services";
import Process from "./Components/Process/Process";
import Tools from "./Components/Tools/Tools";
import Advantages from "./Components/Advantages/Advantages";
import Partners from "./Components/Partners/Partners";
import ContactUs from "./Components/ContactUs/ContactUs";
import ProjectsContainer from "./Components/ProjectsContainer/ProjectsContainer";
import ProjectDetails from "./Pages/ProjectDetails";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import "./index.css";

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <>
        <Routes>
          <Route
            path="/"
            element={
              <div id="start">
                <Header />
                <Introduce />
                <div id="about">
                  <Title text="ABOUT US" id="about-title" />
                  <About />
                </div>
                <div id="services">
                  <Title text="SERVICES" />
                  <Services />
                </div>
                <div id="process">
                  <Title text="PROCESS" />
                  <Process />
                </div>
                <div id="portfolio">
                  <Title text="PORTFOLIO" />
                  <ProjectsContainer />
                </div>
                <div id="tools">
                  <Title text="TOOLS" />
                  <Tools />
                </div>
                <div id="partners">
                  <Title text="OUR PARTNERS" />
                  <Partners />
                </div>
                <div id="advantages">
                  <Title text="OUR ADVANTAGES" />
                  <Advantages />
                </div>
              </div>
            }
          />

          <Route path="/projects/:id" element={<ProjectDetails />} />

        </Routes>
        <ContactUs />
      </>
    </Router>
  );
};

export default App;
