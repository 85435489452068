import React, { useState } from 'react';
import './Square_container.css';

function Square_container(props) {
  const [isArrowRotated, setArrowRotation] = useState(false);
  const [isBlockVisible, setBlockVisibility] = useState(false);
  const [isActive, setActive] = useState(false);

  const handleArrowClick = () => {
    setArrowRotation(!isArrowRotated);
    setBlockVisibility(!isBlockVisible);
    setActive(!isActive);
  };

  return (
    <div className={isActive ? 'square_container active' : 'square_container'}>
      <div className='SquareTitleContainer'>
        {/* <img className="square_icon" src={props.icon} alt="" /> */}
        <div className='square_title'>{props.title}</div>
      </div>


      {/* <div className={isArrowRotated ? "square_arrow_container selected" : "square_arrow_container"} onClick={handleArrowClick}>  
            <svg className={isArrowRotated ? "arrow rotated" : "arrow"} width="2vh" height="3vh" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L14 14M14 14V1.52M14 14H1.52" stroke="#2F2F2F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div> */}

      <div className="content active" >
        {props.content}
      </div>
    </div>
  );
}

export default Square_container;
