import React, { useRef } from 'react'
import './Introduce.css';
import ParticlesContainer from "../Canvas/ParticlesContainer";
import useIntersection from "../../utils/UseIntersection";
import Typewriter from './TypeWriter';
function Introduce() {
  const particlesRef = useRef(null);

  // const inViewport = useIntersection(particlesRef, '0px');
  // console.log('isInViewport: ', inViewport);

  return (
    <section className='introduce' id="introduce">

      <div className={'particles'} ref={particlesRef}>
        {/* <ParticlesContainer count={inViewport ? 40 : 0} /> */}
        <ParticlesContainer count="40" />
      </div>
      <div className='text'>
        <div><span className='blue'>IT</span> CONSULTING <span className='cian'>AND</span></div>
        <div>SOFTWARE</div>
        <div><span className='blue'>DEVELOPMENT</span></div>
      </div>
      <div className='subtext'>
        <Typewriter text="We are a full-cycle web and mobile development company that exercises quality and communication." delay={100} />

      </div>
    </section>
  )
}

export default React.memo(Introduce);
