import React from 'react';
import './ProjectCard.css';

function ProjectCard({ img, title, tags, smallDescription, onClick }) {
  return (
    <div className="ProjectCard" onClick={onClick}>
      <img src={img} alt={title} className="ProjectCard__img" />
      <div className="ProjectCard__content">
        <h3 className="ProjectCard__title">{title}</h3>
        <p className="ProjectCard__description">{smallDescription}</p>
        <div className="ProjectCard__tags">
          {tags.map((tag, index) => (
            <span key={index} className="ProjectCard__tag">
              #{tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
