import React, { useRef, useState } from 'react';
import './ProjectsContainer.css';
import ProjectCard from './ProjectCard';
import projectsData from './portfolio';
import { useNavigate } from 'react-router-dom';

function ProjectsContainer() {
  const projectsGridRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - projectsGridRef.current.offsetLeft);
    setScrollLeft(projectsGridRef.current.scrollLeft);
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - projectsGridRef.current.offsetLeft;
    const walk = x - startX;
    projectsGridRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleCardClick = (id) => {
    navigate(`/projects/${id}`);
  };

  return (
    <div
      className="ProjectsGrid"
      ref={projectsGridRef}
      onMouseDown={startDragging}
      onMouseLeave={stopDragging}
      onMouseUp={stopDragging}
      onMouseMove={onDrag}
    >
      {projectsData.map((project) => (
        <ProjectCard
          key={project.id}
          img={project.img}
          title={project.title}
          tags={project.tags}
          smallDescription={project.smallDescription}
          onClick={() => handleCardClick(project.id)}
        />
      ))}
    </div>
  );
}

export default ProjectsContainer;
